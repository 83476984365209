import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/navigation';
import { Box, Button, Typography } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';
import Swal from 'sweetalert2';

function NewAccount({ signinRedirect }) {
  const router = useRouter();

  const handleImOrganization = () => {
    router.replace('/auth/signup');
  };
  const handleImUser = () => {
    Swal.fire({
      title: '¡Excelente!',
      html: `
      <p>Puedes proponer todos los eventos/talleres y panoramas que quieras siempre cuando seas usuario de Bondup Básico o Bondup Plus, sino te invitamos a contratar tu plan por sólo $3.990.</p>
      <p>Accede aquí, escoge al ítem de panoramas y crea tu propio taller! puede ser gratuito o pagado.</p>
    `,
      icon: 'info',
      confirmButtonText: 'Ir al inicio de sesión',
    }).then((value) => {
      if (value.isConfirmed) signinRedirect();
    });
  };

  return (
    <>
      <Typography variant="h6">¡Bienvenido! Cuéntanos un poco más sobre tí</Typography>
      <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
        <Button
          sx={{ minWidth: '250px', flex: 1 }}
          variant="contained"
          color="primary"
          startIcon={<ApartmentIcon />}
          onClick={handleImOrganization}
        >
          <Typography align="justify">
            Soy parte de una empresa o soy persona natural y quiero publicar mis talleres/panoramas
            en BondUP
          </Typography>
        </Button>
        <Button
          sx={{ minWidth: '250px', flex: 1 }}
          variant="contained"
          color="secondary"
          startIcon={<PersonIcon />}
          onClick={handleImUser}
        >
          <Typography align="justify">
            Soy un usuario de BondUP y quiero proponer panoramas para la comunidad
          </Typography>
        </Button>
      </Box>
    </>
  );
}

NewAccount.propTypes = {
  signinRedirect: PropTypes.func,
};

NewAccount.defaultProps = {
  signinRedirect: () => {},
};

export default NewAccount;
