import { useCallback, useState } from 'react';
import { Box, Stack, Typography, Tab, Tabs } from '@mui/material';
import { Layout as AuthLayout } from 'src/layouts/auth/layout';

import Signin from 'src/sections/auth/Signin';
import NewAccount from 'src/sections/auth/NewAccount';

const views = [
  {
    value: 'signin',
    label: 'Ya tengo cuenta',
  },
  {
    value: 'signup',
    label: 'Quiero crear una cuenta',
  },
];

const Page = () => {
  const [selectedView, setSelectedView] = useState(views[0].value);

  const handleViewChange = useCallback((event, value) => setSelectedView(value), []);
  const handleSigninRedirect = useCallback(() => (window.location.href = 'https://app.bondup.cl/'));

  return (
    <Box
      sx={{
        maxWidth: '700px',
        px: 3,
        py: '100px',
      }}
    >
      <div>
        <Stack spacing={1} sx={{ mb: 3 }}>
          <Typography variant="h4">Bienvenido al Marketplace de BondUP</Typography>
        </Stack>
        <Tabs onChange={handleViewChange} sx={{ mb: 3 }} value={selectedView}>
          {views.map((view) => (
            <Tab key={view.value} label={view.label} value={view.value} />
          ))}
        </Tabs>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            minHeight: '400px',
          }}
        >
          {selectedView === 'signin' && <Signin />}
          {selectedView === 'signup' && <NewAccount signinRedirect={handleSigninRedirect} />}
        </Box>
      </div>
    </Box>
  );
};

Page.getLayout = (page) => <AuthLayout title="Inicio sesión">{page}</AuthLayout>;

export default Page;
