import PropTypes from 'prop-types';
import NextLink from 'next/link';
import Head from 'next/head';
import { Box } from '@mui/material';
import { Logo } from 'src/components/logo';

export const Layout = (props) => {
  const { children, title } = props;

  const Header = () => (
    <Box
      component="header"
      sx={{
        left: 0,
        p: 3,
        position: 'fixed',
        top: 0,
        width: '100%',
      }}
    >
      <Box
        component={NextLink}
        href="/"
        sx={{
          display: 'inline-flex',
          height: 32,
          width: 32,
        }}
      >
        <Logo />
      </Box>
    </Box>
  );

  return (
    <>
      <Head>
        <title>{title ? title : '[Nombre]'} - BondUP Marketplace</title>
      </Head>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: '1 ',
        }}
      >
        <Header />
        <Box
          sx={{
            backgroundColor: 'background.paper',
            flex: '1',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

Layout.prototypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};
