import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useAuth } from 'src/hooks/use-auth';

function SigninForm() {
  const auth = useAuth();

  const signinForm = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Debe ser un correo válido')
        .max(255)
        .required('El correo es requerido'),
      password: Yup.string().max(255).required('La contraseña es requerida'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await auth.signIn(values.email, values.password);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={signinForm.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          error={!!(signinForm.touched.email && signinForm.errors.email)}
          fullWidth
          helperText={signinForm.touched.email && signinForm.errors.email}
          label="Correo electrónico"
          name="email"
          onBlur={signinForm.handleBlur}
          onChange={signinForm.handleChange}
          type="email"
          value={signinForm.values.email}
        />
        <TextField
          error={!!(signinForm.touched.password && signinForm.errors.password)}
          fullWidth
          helperText={signinForm.touched.password && signinForm.errors.password}
          label="Contraseña"
          name="password"
          onBlur={signinForm.handleBlur}
          onChange={signinForm.handleChange}
          type="password"
          value={signinForm.values.password}
        />
      </Stack>
      {signinForm.errors.submit && (
        <Typography color="error" sx={{ mt: 3 }} variant="body2">
          {signinForm.errors.submit}
        </Typography>
      )}
      <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
        Iniciar sesión
      </Button>
    </form>
  );
}

export default SigninForm;
