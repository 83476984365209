import React from 'react';
import NextLink from 'next/link';
import { Typography } from '@mui/material';
import SigninForm from './SigninForm';

function Signin() {
  return (
    <>
      <Typography variant="h6">Inicia sesión con tu correo y contraseña</Typography>
      <SigninForm />
      <NextLink align="center" href="/forgotPassword" style={{ cursor: 'pointer' }}>
        ¿Has olvidado la contraseña?
      </NextLink>
    </>
  );
}

export default Signin;
